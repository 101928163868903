import { ReactNode } from 'react';

type Props = {
  caption: ReactNode;
  title: ReactNode;
  className?: string;
  headingFont?: string;
};

const Header = (props: Props) => {
  const {
    caption,
    title,
    className,
    headingFont = 'text-heading3 md:text-4xl lg:text-headingLarge2 mb-3 md:mb-5 '
  } = props;
  return (
    <div
      className={`z-50 relative flex flex-col justify-center text-center w-full px-4 md:px-8 md:mb-2 ${className}`}
    >
      <h5 className="h5 noise-text2 text-xs tracking-titleMobileSpacing md:tracking-sectionSubtitle 
      md:text-base font-bold mb-1 md:mb-7">
        {caption}
      </h5>
      <h1
        className={`${headingFont} tracking-sectionHeading font-semibold md:font-semibold text-black dark:text-main`}
      >
        {title}
      </h1>
    </div>
  );
};

export default Header;
