import React from 'react';

const Features = () => {
  return (
    <>
      <div className="max-w-commentsblock features-block px-5 m-auto pt-12 dark:bg-transparent relative">
        <div className="m-auto md:px-5 max-w-5xl">
          <div className="feature-heading px-4 mb-14 md:px-0">
            <h2 className="noise-text2 text-xs leading-[30px] md:text-title mb-2 md:mb-4 font-bold
            tracking-sectionMobileSubtitle md:tracking-sectionSubtitle text-center md:text-base">
              FEATURES
            </h2>
            <div className='text-center flex justify-center align-center'>
            <h3 className="md:text-center font-semibold leading-[38px] w-[337px] tracking-sectionMobileHeading text-heading7 mb-3
            dark:text-bgLight md:text-headingLarge2 md:mb-2 md:leading-snug md:w-[983px] md:tracking-sectionHeading"
            >
              Revolutionize your workforce management
            </h3>
            </div>
            <div
              className="opacity-25 dark:opacity-35 absolute bottom-0 right-[-758px] top-[1961px]
              translate -translate-y-[1961px] dark:bg-primary
          bg-blueColor03 noise blur-250 w-[900px] h-[900px] -z-10 hidden md:block"
            />
            <p className="text-center pt-3 leading-sung font-normal max-w-3xl mx-auto md:mb-12
            md:text-sectionHeadfontSize20 leading-sectionHeadLineHeight text-blocksText dark:text-bgLight
            opacity-textOpacity">
              Experience increased productivity, real-time insights, and
              automated tasks. Say goodbye to manual processes and hello to a
              more efficient workday.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
