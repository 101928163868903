import { JiraIcon, LockIcon } from '@/assets';
import { Content, Header } from '../Section';
import Slider1 from '../Slider';
import LazyLoad from 'react-lazyload';


const generateSliderData = (pattern: string[]) => {
  let sliderData = [];
  for (let i = 0; i < 14; i++) {
    let iconType = pattern[i % pattern.length];
    let icon;
    let text;
    switch(iconType) {
      case 'jira':
        icon = <JiraIcon width={25} />;
        text = 'Jira';
        break;
      case 'lock':
        icon = <LockIcon width={25} />;
        text = 'Coming Soon';
        break;
      default:
        console.error(`Unknown icon type ${iconType}`);
        icon = null;
        text = '';
    }
    sliderData.push({ icon, text });
  }
  return sliderData;
};


const Index = () => {
  const slider1 = generateSliderData(['jira', 'lock', 'lock']);
  const slider2 = generateSliderData(['lock', 'lock', 'lock', 'jira']);
  return (
    <div className="relative mb-4 md:mb-0 pt-6 md:pt-8 md:mt-8">
      <Header
        title="Instant access to the best tools"
        caption="INTEGRATIONS"
        className="pt-8 mt-2 md:mb-7  "
      />
      <Content className="flex flex-col">
      <div
            className="opacity-25 dark:opacity-70 absolute rounded-full dark:bg-primary top-[10px] left-[55px]
          bg-blueColor03 noise blur-125 w-[268px] h-[267px] -z-10 md:hidden "
          />
        <div
          className="opacity-25 dark:opacity-70 absolute -bottom-10 left-1/2 top-1/2 
          transform -translate-x-1/2 -translate-y-1/2 dark:bg-blueColor05
          bg-blueColor03 noise blur-250 md:w-[438px] md:h-[438px] -z-10 "
        />
          <LazyLoad height={200} once>
          <Slider1
            bg="bg-white text-sponsersColor dark:bg-blurColor dark:text-main"
            data={slider1}
          />
        </LazyLoad>
        <LazyLoad height={200} once>
          <Slider1
            bg="bg-white text-sponsersColor dark:bg-blurColor dark:text-main"
            data={slider2}
          />
        </LazyLoad>
      </Content>
    </div>
  );
};

export default Index;
