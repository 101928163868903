import React from 'react';
import { LockIcon } from '@/assets';
import Image from 'next/image';

interface IFeatureblock {
  title: string;
  description?: string;
  paragraph?: string;
  mobileImage?: string;
  mobileImageAlt?: string;
  desktopImage?: string;
  featureDesktop?: string;
  lightMobile?: string;
  lightMobileAlt?: string;
  blurDesktopImage?: boolean;
  comingSoon?: boolean;
  blurVisualImage?: boolean;
  blurBannerImage?: boolean;
}
const Featureblock: React.FC<IFeatureblock> = ({
  title,
  description,
  desktopImage,
  mobileImageAlt,
  mobileImage,
  paragraph,
  featureDesktop,
  lightMobile,
  lightMobileAlt,
  blurDesktopImage = false,
  comingSoon = false
}) => {
  return (
    <div className="max-w-commentsblock m-auto md:w-full">
      <div className="box w-full mb-4">
        <div className="two-cols md:flex px-4 md:px-2">
          <div
            className="col z-10 relative rounded-3xl -mb-3 px-7 py-5 text-center md:items-center
            md:justify-between md:m-auto md:flex md:text-left bg-bgLight dark:bg-blurColor"
          >
            <div className="text-holder md:p-2.5 w-full md:w-6/12 xl:w-4/12 rtl:text-right">
              <h4
                className="md:pt-4 text-heading5 md:text-heading3 leading-normal font-semibold mb-3 
              md:mb-5 text-blocksText tracking-featureLetterSpace dark:text-white"
              >
                {title}
              </h4>
              {description && (
                <p className="text-heading md:text-lg leading-normal px-6 md:px-0 mb-7 text-blocksText dark:text-main">
                  {description}
                </p>
              )}
              {paragraph && (
                <p className="text-heading md:text-lg leading-normal mb-4 text-blocksText dark:text-main">
                  {paragraph}
                </p>
              )}
            </div>
            <div
              className="image-holder flex-col md:flex-row justify-center flex w-full flex-wrap 
            md:flex-nowrap md:p-2 xl:pl-8 xl:pt-1 md:w-6/12 xl:w-8/12"
            >
              {mobileImage && (
                <div className="mb-3.5 md:hidden -mx-2 relative">
                  <Image
                    className={`h-full object-cover justify-center hidden dark:block ${
                      blurDesktopImage ? 'blur-sm' : ''
                    }`}
                    src={mobileImage}
                    alt="MobileImage"
                    width={300}
                    height={300}
                  />
                  {comingSoon && (
                    <div
                      className="absolute flex flex-col justify-center items-center top-[50%] left-[50%]
      transform -translate-x-[50%] -translate-y-[50%]"
                    >
                      <LockIcon className="w-30 h-30 mb-1 hidden dark:block" />
                      <span className="text-xl font-bold text-black-700 dark:text-gray-200 hidden dark:block">
                        Coming Soon
                      </span>
                    </div>
                  )}
                </div>
              )}
              {mobileImageAlt && (
                <div className="md:hidden -mx-2 relative">
                  <Image
                    className={`h-full object-cover justify-center hidden dark:block ${
                      blurDesktopImage ? 'blur-sm' : ''
                    }`}
                    src={mobileImageAlt}
                    alt="MobileImageAlt"
                    width={3000}
                    height={3000}
                  />
                  {comingSoon && (
                    <div
                      className="absolute flex flex-col justify-center items-center top-[50%] left-[50%]
      transform -translate-x-[50%] -translate-y-[50%]"
                    >
                      <LockIcon className="w-30 h-30 mb-1 hidden dark:block" />
                      <span className="text-xl font-bold text-black-700 dark:text-gray-200 hidden dark:block">
                        Coming Soon
                      </span>
                    </div>
                  )}
                </div>
              )}
              {desktopImage && (
                <div className="md:block hidden relative">
                  <Image
                    className={`h-full w-full object-cover justify-center hidden dark:block ${
                      blurDesktopImage ? 'blur-sm' : ''
                    }`}
                    src={desktopImage}
                    alt="DesktopImage"
                    width={3000}
                    height={3000}
                  />
                  {comingSoon && (
                    <div
                      className="absolute flex flex-col justify-center items-center top-[50%] left-[50%]
                    transform -translate-x-[50%] -translate-y-[50%]"
                    >
                      <LockIcon className="w-30 h-30 mb-1 hidden dark:block" />
                      <span className="text-xl font-bold text-black-700 dark:text-gray-200 hidden dark:block">
                        Coming Soon
                      </span>
                    </div>
                  )}
                </div>
              )}
              {featureDesktop && (
                <div className=" md:block hidden relative">
                  <Image
                    className={`h-full w-full object-cover justify-center block dark:hidden ${
                      blurDesktopImage ? 'blur-sm' : ''
                    }`}
                    src={featureDesktop}
                    alt="FeatureDesktop"
                    width={3000}
                    height={3000}
                  />
                  {comingSoon && (
                    <div
                      className="absolute flex flex-col justify-center items-center top-[50%] left-[50%] 
                    transform -translate-x-[50%] -translate-y-[50%]"
                    >
                      <LockIcon className="w-30 h-30 mb-1 dark:hidden block" />
                      <span className="text-xl font-bold text-black-700 dark:text-gray-200 dark:hidden block">
                        Soon
                      </span>
                    </div>
                  )}
                </div>
              )}
              {lightMobile && (
                <div className="mb-2.5 md:hidden relative">
                  <Image
                    className={`h-full object-cover justify-center block dark:hidden ${
                      blurDesktopImage ? 'blur-sm' : ''
                    }`}
                    src={lightMobile}
                    alt="LightMobile"
                    width={3000}
                    height={3000}
                  />
                  {comingSoon && (
                    <div
                      className="absolute flex flex-col justify-center items-center top-[50%] left-[50%]
      transform -translate-x-[50%] -translate-y-[50%]"
                    >
                      <LockIcon className="w-30 h-30 mb-1 block dark:hidden" />
                      <span className="text-xl font-bold text-black-700 dark:text-gray-200 block dark:hidden">
                        Coming Soon
                      </span>
                    </div>
                  )}
                </div>
              )}
              {lightMobileAlt && (
                <div className="mb-2.5 md:hidden relative">
                  <Image
                    className={`h-full object-cover justify-center block dark:hidden ${
                      blurDesktopImage ? 'blur-sm' : ''
                    }`}
                    src={lightMobileAlt}
                    alt="LightMobileAlt"
                    width={3000}
                    height={3000}
                  />
                  {comingSoon && (
                    <div
                      className="absolute flex flex-col justify-center items-center top-[50%] left-[50%]
      transform -translate-x-[50%] -translate-y-[50%]"
                    >
                      <LockIcon className="w-30 h-30 mb-1 block dark:hidden" />
                      <span className="text-xl font-bold text-black-700 dark:text-gray-200 block dark:hidden">
                        Coming Soon
                      </span>
                    </div>
                  )}
                </div>
              )}
              <div
                className="opacity-25 dark:opacity-35 absolute top-[401px] transform translate-y-[450px] 
                translate-x-[450px] right-[-672px] dark:bg-blueColor02
              bg-blueColor03 noise blur-250 w-[900px] h-[900px] -z-10 md:block"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featureblock;
