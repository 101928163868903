const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-extrabold tracking-tight text-sky-700 mb-8">
        Chronly Privacy Policy 
      </h1>
      <p className="mb-8">
        Last updated: April 17, 2023
      </p>
      <p className="mb-8">
        Chronly Inc. (&ldquo;Chronly,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;) values your privacy and is committed to protecting your personal information. This Privacy Policy describes how we collect, use, and share your personal information when you use our AI-based workforce management tool available at chronly.(the &ldquo;Platform&rdquo;) and any associated services provided by us (collectively, the &ldquo;Services&rdquo;).
      </p>
      <p className="mb-8">
        By using our Services, you agree to the collection, use, and disclosure of your personal information as described in this Privacy Policy. If you disagree with the terms of this Privacy Policy, please do not use our Services.
      </p>
      <h2 className="text-2xl font-bold mb-4">Information We Collect</h2>
      <p className="mb-8">
        <span className="font-bold">1.1 Information You Provide to Us</span>
        <br />
        When you register for an account with Chronly, we collect personal information such as your name, email address, company name, and password. We also collect the information you provide when you create or modify your profile, set preferences, sign up for or make purchases through the Services.
      </p>
      <p className="mb-8">
        <span className="font-bold">1.2 Information We Collect Automatically</span>
        <br />
        When you use the Services, we automatically collect certain information, including:
      </p>
      <ul className="list-disc list-inside mb-8">
        <li>Usage data: We collect information about your interactions with the Services, such as the features you use, the actions you take, and the time and duration of your activities.</li>
        <li>Device and connection information: We collect information about the devices you use to access the Services, including device hardware and software information, device identifiers, and network information.</li>
        <li>Location information: We collect information about your approximate location based on your IP address.</li>
        <li>Cookies and similar technologies: We use cookies and similar technologies to collect information about your use of the Services and to remember your preferences.</li>
      </ul>
      <h2 className="text-2xl font-bold mb-4">How We Use Your Information</h2>
      <p className="mb-8">
        We use your personal information to:
      </p>
      <ul className="list-disc list-inside mb-8">
        <li>Provide, maintain, and improve the Services.</li>
        <li>Communicate with you about the Services, send you important notices, and respond to your inquiries.</li>
        <li>Personalize your experience with the Services, such as by displaying content based on your preferences.</li>
        <li>Monitor and analyze usage, trends, and activities related to the Services.</li>
        <li>Detect, investigate, and prevent fraud, unauthorized access, and other illegal activities.</li>
        <li>Enforce our terms of service and other agreements.</li>
        <li>Comply with applicable laws and regulations.</li>
      </ul>
      <h2 className="text-2xl font-bold mb-4">How We Share Your Information</h2>
      <p className="mb-8">
        We may share your personal information in the following circumstances:
      </p>
      <ul className="list-disc list-inside mb-8">
        <li>With third-party service providers who perform services on our behalf, such as payment processing, analytics, and customer support.</li>
        <li>In response to legal processes, such as a court order or subpoena, or when we have a good faith belief that disclosure is necessary to protect our rights, your safety, or the safety of others.</li>
        <li>In connection with a merger, acquisition, or sale of assets, where your personal information may be transferred to a new entity.</li>
        <li>With your consent or at your direction.</li>
        <li>We may also share aggregated or de-identified information, which cannot reasonably be used to identify you.</li>
      </ul>
      <h2 className="text-2xl font-bold mb-4">Data Retention</h2>
      <p className="mb-8">
        We retain your personal information for as long as necessary to provide the Services and for other legitimate business purposes, such as complying with our legal obligations, resolving disputes, and enforcing our agreements.
      </p>
      <h2 className="text-2xl font-bold mb-4">Security</h2>
      <p className="mb-8">
        We implement reasonable physical, technical, and administrative measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission or storage is completely secure, and we cannot guarantee the absolute security of your personal information.
      </p>
      <h2 className="text-2xl font-bold mb-4">Children&rsquo;s Privacy</h2>
      <p className="mb-8">
        Our Services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information without parental consent, please contact us at hello@chronly.ai, and we will take steps to remove the information and terminate the child&rsquo;s account.
      </p>
      <h2 className="text-2xl font-bold mb-4">International Data Transfers</h2>
      <p className="mb-8">
        Your personal information may be transferred to, stored, and processed in countries other than the country in which it was originally collected, including the United States and other countries where our service providers maintain facilities. These countries may have different data protection laws than your country of residence. By using the Services, you consent to the transfer of your personal information to countries outside your country of residence.
      </p>
      <h2 className="text-2xl font-bold mb-4">Your Privacy Rights</h2>
      <p className="mb-8">
        Depending on your location and applicable laws, you may have certain rights with respect to your personal information, such as the right to access, correct, delete, or restrict the processing of your personal information. To exercise any of these rights, please contact us at hello@chronly.ai.
      </p>
      <h2 className="text-2xl font-bold mb-4">Changes to This Privacy Policy</h2>
      <p className="mb-8">
        We may update this Privacy Policy from time to time to reflect changes in our privacy practices or applicable laws. We will post any changes to this Privacy Policy on this page, and we will indicate the date the Privacy Policy was last updated at the top of the page. Your continued use of the Services following the posting of any changes to this Privacy Policy constitutes your acceptance of those changes.
      </p>
      <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
      <p className="mb-2">
        If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
      </p>
      <address className="not-italic mb-8">
        Chronly Inc.<br />
        State: Delaware<br />
        Street: 1007 N Orange St. 4th Floor Ste 1382<br />
        ZIP: 19801<br />
        City: Wilmington<br />
        Email: <a href="mailto:hello@chronly.ai" className="text-blue-500 hover:underline">hello@chronly.ai</a>
      </address>
      <p>
        Please note that email communications are not always secure, so please do not include sensitive information in your emails to us.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
