import { ReactElement, ReactNode } from 'react';
import Card from '../Card';
import Image from 'next/image';


type Props = {
  testimonial: string | ReactNode | ReactElement;
  img?: string;
  name?: string;
  description: string;
  className?: string;
  imgClassName?: string;
};

const AboutCard = (props: Props) => {
  const {
    img = '/images/person1.webp',
    name,
    description,
    className,
    testimonial,
    imgClassName,
  } = props;
  return (
    <Card
      className={`p-4 text-center md:m-0 md:text-left md:py-8 px-7 md:px-12 ${className}`}
    >
      {testimonial}
      <div className="flex flex-col justify-center items-center md:flex-row pt-3 pb-2 md:pb-5 
      space-x-0 rtl:space-x-0 space-y-1 md:space-y-0 md:justify-start">
        <Image 
        src={img} 
        alt="person"
        width={100}
        height={100}
        className={`w-avatarMobileWidth md:w-avatarWidth h-avatarMobileHeight 
        md:h-avatarHeight rounded-full rtl:ml-2 ${imgClassName}`} />
        <div className="pt-3 md:pt-0 flex flex-col items-cneter md:text-start " >
          <h3 className="m-0 mb-1 md:mb-2 dark:text-bgLight text-blocksText text-sectionHeadfontSize20 
          md:text-2xl font-semibold tracking-featureLetterSpace">{name}</h3>
          <p className="m-0 dark:text-bgLight text-blocksText opacity-textOpacity text-xs md:text-sm">{description}</p>
        </div>
      </div>
    </Card>
  );
};

export default AboutCard;
