import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer py-14 md:pt-28 md:pb-14 relative bg-bgLight max-w-containerBig mx-auto dark:bg-blurColor">
      <div className="max-w-containerLarge m-auto z-50 relative px-20 md:px-5">
        <div className="md:flex md:mb-9">
          <div className="md:w-5/12">
            <strong className="logo block leading-normal text-base mb-2 md:mb-7 h-auto w-logMobileWidthLarge md:w-logoWidthLarge">
              <Link href="#" className="block w-full">
                <Image
                  width={100}
                  height={100}
                  src="/assets/images/logoDark.svg"
                  alt="Chronly Logo"
                  className="w-full h-auto hidden dark:block"
                />
                <Image
                  width={100}
                  height={100}
                  src="/assets/images/logoLight.svg"
                  alt="logoLight"
                  className="w-full h-auto block dark:hidden"
                />
              </Link>
            </strong>
            <p
              className="mb-9 md:mb-7 md:text-lg font-normal md:max-w-md text-blocksText dark:text-white 
            opacity-textOpacity"
            >
              Wave goodbye to development delays with Chronly. Dive into a
              simpler way to keep your projects on time and budget. No more
              guesswork, just clear insights.
            </p>
            <ul className="social-networks flex mb-10 md:mb-9">
              <li className="mr-5 md:mr-8 rtl:mr-0 rtl:ml-6">
                <Link
                  href="https://twitter.com/ChronlyAI"
                  target="_blank"
                  aria-disabled="false"
                  aria-label="X"
                  className="rounded-full w-6 h-6 flex justify-center items-center p-1 md:p-3 md:w-12 md:h-12
                  dark:bg-blocksText text-gray bg-white cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faXTwitter as IconProp}
                    className="w-full h-3 md:h-5"
                  />
                </Link>
              </li>
              <li className="mr-5 md:mr-8 rtl:mr-0 rtl:ml-6">
                <Link
                  href="https://www.linkedin.com/company/chronly/"
                  target="_blank"
                  aria-disabled="false"
                  aria-label="LinkedIn"
                  className="rounded-full w-6 h-6 flex justify-center items-center p-1 md:p-3 md:w-12 md:h-12 
                  dark:bg-blocksText text-gray bg-white cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faLinkedinIn as IconProp}
                    className="w-full h-3 md:h-5"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="flex flex-col md:text-lg text-blocksText gap-2
        dark:text-white opacity-textOpacity"
        >
          <p className="mb-2 md:mb-0 text-mobileTitle md:text-heading6">
            <Link
              href="/cookie"
              target="_blank"
              className="text-blocksText dark:text-white opacity-textOpacity "
            >
              Cookie Policy
            </Link>
            <span className="mx-2">•</span>
            <Link
              href="/privacy"
              target="_blank"
              className="text-blocksText dark:text-white opacity-textOpacity "
            >
              Privacy Policy
            </Link>
            <span className="mx-2">•</span>
            <Link
              href="/terms"
              target="_blank"
              className="text-blocksText dark:text-white opacity-textOpacity "
            >
              Terms &amp; Conditions
            </Link>
          </p>
          <p className="mb-0 text-mobileTitle md:text-heading6 text-blocksText dark:text-white opacity-textOpacity ">
            &copy; 2021-{currentYear} Chronly, All rights reserved.
          </p>
        </div>
      </div>
      <div
        className="opacity-25 dark:opacity-75 absolute bottom-64 md:top-0 left-[-172px] md:left-[-391px] dark:bg-primary
          bg-blueColor03 noise blur-125 md:blur-250 w-[268px] h-[268px] md:w-[492px] md:h-[492px] -z-60 md:block"
      />
      <div
        className="opacity-25 dark:opacity-75 absolute -bottom-12 md:bottom-0 md:top-1/2 right-[-200px] md:right-[-307px] dark:bg-primary
          bg-blueColor03 noise blur-125 md:blur-250 w-[268px] h-[268px] md:w-[492px] md:h-[492px] -z-60 md:block"
      />
    </div>
  );
};

export default Footer;
