import React from 'react';
import { NoiseImageEffect } from '../NoiseEffect';
import { useSelector } from 'react-redux';
import Image from 'next/image';
import { RootState } from '@/redux/store';

const DataTeritorry = () => {
  const { theme } = useSelector((state: any) => state.theme);

  return (
    <>
      <div className="pt-8 md:pt-1 md:pb-8 relative md:mb-20 md:static">
        <div className="absolute left-0 -z-50 top-0 dark:top-0 md:top-0 overflow-hidden right-0">
          {theme === 'dark' && (
            <Image
              src="/assets/images/minimal-globe-technology-business-background.jpg"
              alt="bgimg"
              width={612}
              height={407}
              priority
              className="relative w-full h-full hidden dark:block pt-1 mt-20 blur-xl
              md:dark:hidden md:w-full md:h-full md:-mt-40 md:left-1/2 md:top-0"
            />
          )}

          <div
            className="relative left-1/2 transform -translate-x-1/2 w-full h-full max-w-[1920px] lg:w-[2204px]
            lg:h-[1469px] md:w-[1920px] sm:w-[800px] md:max-w-none bg-gradient-to-t from-[#0D0D0D] to-transparent
            hidden md:dark:block"
          >
            <Image
              src="/assets/images/bgimg-desk.webp"
              alt="bgimg"
              fill
              priority
              style={{ objectFit: 'fill' }}
              className="absolute w-full h-full"
            />
          </div>
          <div
            className="relative top-0 left-1/2 transform -translate-x-1/2 w-full h-auto max-w-[1920px] lg:w-[2204px]
            md:w-[1920px] sm:w-[800px] md:mt-80 md:max-w-none dark:hidden"
          >
            <Image
              src="/assets/images/bgimgwhite.webp"
              alt="bgimg"
              fill
              style={{ objectFit: 'fill' }}
              priority
              className="absolute w-full h-full"
            />
          </div>
        </div>
        <div className="relative px-3 md:px-4 m-auto">
          <div className="relative mx-auto hidden md:block rounded-2xl w-[1279px] h-[719px] max-w-full dark:hidden">
            <Image
              width={1279}
              height={719}
              src="/assets/images/topscreenshotlight.webp"
              alt="topScreenLight"
              className=" inset-0 object-cover z-10 h-auto w-auto rounded-topScreenRadius dark:hidden"
              priority
            />
            <Image
              width={1279}
              height={719}
              src='/assets/images/MPLight.png'
              alt="LightRemoteTrackerLanding"
              className=" inset-0 object-cover z-10 rounded-screenRadius dark:hidden"
              priority
            />
          </div>
          <div className="relative mx-auto hidden dark:md:block rounded-2xl w-[1278px] h-[718px] ">
            <Image
              width={1279}
              height={719}
              src="/assets/images/topscreenshot.webp"
              alt="topScreen"
              className=" inset-0 object-cover z-10 h-auto w-auto rounded-topScreenRadius"
              priority
            />
            <NoiseImageEffect
              backgroundImage=""
              className="inset-0 object-cover w-full h-full z-10 "
              borderRadius="rounded-screenRadius"
            >
              <Image
                src="/assets/images/MPDark.png"
                width={1278}
                height={718}
                alt="RemoteTrackerLanding"
                className="rounded-screenRadius w-full h-full"
                priority
              />
            </NoiseImageEffect>
          </div>

          <div
            className="opacity-50 dark:opacity-100 absolute -bottom-10 left-1/2 top-1/2 transform -translate-x-1/2
            -translate-y-1/2 dark:bg-primary
          bg-blueColor03 noise blur-250 w-[486px] h-[486px] -z-10 "
          />
          <div
            className="opacity-25 dark:opacity-50 absolute -bottom-10 left-1/2 top-1/2 transform -translate-x-1/2
            -translate-y-1/2 dark:bg-blueColor01
          bg-blueColor03 noise blur-250 md:w-[486px] md:h-[486px] sm:w-1/2 sm:h-1/2 -z-10 "
          />
        </div>
      </div>
    </>
  );
};
export default DataTeritorry;
