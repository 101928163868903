import React from 'react';

interface IButton {
  btntype?: string;
  className?: string;
}

const Button: React.FC<IButton> = ({ btntype, className }) => {
  return (
    <>
      <div className={`${className}`}>
        <button className="bg-primary btn-noise hover:bg-btnHover focus:bg-btnHover
        focus:border-btnBorderFocus text-white rounded-full text-center text-base md:text-lg 
          font-semibold	md:font-semibold py-2.5 px-6 md:py-3.5 md:px-8  transition-all">
          {btntype}
        </button>
      </div>
    </>
  );
};
export default Button;
