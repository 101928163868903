'use client';
import useThemeToggle from '@/hooks/useThemeToggle';
import Link from 'next/link';
import React, { useState, useEffect } from 'react';
import MobileMenu from '../MobileMenu';
import Image from 'next/image';

const NavMenu: any[] = [
  // { menu: 'About us' },
  // { menu: 'Pricing' },
  // { menu: 'Rewards' },
  // { menu: 'Partners' },
  // { menu: 'How it works' },
  // { menu: 'Careers' },
  // { menu: 'Blog' }
];

const Header = () => {
  const { toggleTheme } = useThemeToggle();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [theme, setTheme] = useState<boolean>(true);

  useEffect(() => {
    const colorTheme = localStorage.getItem('color-theme');
    if (colorTheme === 'light') return setTheme(true);
    setTheme(false);
  }, [toggleTheme]);

  return (
    <>
      <header className="py-8 md-py-6 md:py-headerPadding relative">
      <div
            className="opacity-25 dark:opacity-35 absolute rounded-full dark:bg-primary -top-[103px] left-[271px]
          bg-blueColor03 noise blur-125 w-[270px] h-[270px] -z-10 md:hidden "
          />
        <div className="mx-auto px-20 flex justify-between items-center max-w-containerLarge">
          <strong className="logo w-logoMobileWidth md:w-24 xl:w-logoWidth">
            <Link href="#">
              <Image
                width={100}
                height={100}
                src="/assets/images/logoDark.svg"
                alt="logoDark"
                className="hidden dark:block"
              />
              <Image
                width={100}
                height={100}
                src="/assets/images/logoLight.svg"
                alt="logoLight"
                className="block dark:hidden"
              />
            </Link>
          </strong>
          <div className="hidden md:block">
            <ul className="flex xl:pl-12 3xl:pl-16">
              {NavMenu.map((data) => (
                <li key={data.menu}>
                  <Link
                    href="/"
                    className="mx-2 md:mx-2 xl:mx-7 text-base md:text-xs lg:text-sm xl:text-base 
                    xl:font-normal dark:text-main text-black"
                  >
                    {data.menu}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="relative hidden md:block">
            <div className="flex items-center">
              <Link
                href="https://app.chronly.ai/"
                target="_blank"
                className="mr-2 lg:mr-6 text-base md:text-headingLarge4 dark:btn-noise lg:text-base 
                dark:text-bgLight text-black hover:underline"
              >
                Login
              </Link>
              <Link
                href="https://app.chronly.ai/sign-up"
                target="_blank"
                className={`${
                  theme && 'btn-noise'
                } border py-2 px-3 lg:py-3 ld:px-6 rounded-btnRadius xl:w-btnWidth shadow-btnShadow 
                xl:flex xl:justify-center xl:items-center
                hover:bg-btnHover focus:bg-btnHover focus:border-btnBorderFocus dark:focus:bg-btnDarkFocus
                dark:bg-btnDarkFillColor dark:hover:bg-btnDarkHover dark:border-primary border-transparent dark:border-2
                dark:bgLight text-white bg-primary text-base md:text-title p-btnPadding transition-all`}
              >
                Register
              </Link>
              <div className="cursor-pointer 3xl:-mr-12 ml-2 md:ml-3 3xl:ml-6">
                <Image
                  width={24}
                  height={24}
                  onClick={toggleTheme}
                  src="/assets/images/sun.svg"
                  alt="sun"
                  className="hidden dark:block"
                />
                <Image
                  width={24}
                  height={24}
                  onClick={toggleTheme}
                  src="/assets/images/moon.svg"
                  alt="moon"
                  className="dark:hidden"
                />
              </div>
            </div>
          </div>

          <div className="flex items-center md:hidden">
            <div className="cursor-pointer mx-3">
              <Image
                width={24}
                height={24}
                onClick={toggleTheme}
                src="/assets/images/sun.svg"
                alt="sun"
                className="hidden dark:block"
              />
              <Image
                width={24}
                height={24}
                onClick={toggleTheme}
                src="/assets/images/moon.svg"
                alt="sun"
                className="block dark:hidden"
              />
            </div>
            <div
              onClick={() => {}}
              className=" flex-col justify-right hidden cursor-pointer"
            >
              <div className="w-4 h-0.5 bg-primary mb-1 ml-1"></div>
              <div className="w-5 h-0.5 bg-primary mb-1"></div>
              <div className="w-3 h-0.5 bg-primary ml-2"></div>
            </div>
          </div>
          <div className="opacity-headerShadow absolute -top-topBlurUp -left-topBlurLeft 
          bg-topBlurColor blur-175 md:blur-250 w-64 h-64 md:w-topBlur md:h-topBlur -z-10 rounded-full hidden md:block" />
          <div className="opacity-headerShadow absolute -top-topBlurUp -right-topBlurRight bg-topBlurColor 
          lur-175 md:blur-250 w-64 h-64 md:w-topBlur md:h-topBlur -z-10 rounded-full" />
        </div>
      </header>
      {showMobileMenu && <MobileMenu toggleMenu={setShowMobileMenu} />}
    </>
  );
};
export default Header;
