const CookiePolicy = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-extrabold tracking-tight text-sky-700 mb-8">
        COOKIE POLICY
      </h1>
      <p className="mb-8">
        Last updated July 3, 2023
      </p>
      <p className="mb-8">
        This Cookie Policy explains how Chronly Inc. (&ldquo;Company,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; &ldquo;our,&rdquo; or &ldquo;Chronly&rdquo;) uses cookies and similar technologies to recognize you when you visit our website at https://chronly.ai (&ldquo;Website&rdquo;). It explains what these technologies are and why we use them, just like how our AI technology helps optimize your workforce. It also details your rights to control our use of them.
      </p>
      <p className="mb-8">
      Sometimes, we may use cookies to collect personal information, or that becomes personal information when combined with other details.
      </p>
      <h2 className="text-2xl font-bold mb-4">What are cookies?</h2>
      <p className="mb-8">
        Think of cookies as small digital neurons that are placed on your computer or mobile device when you visit a website. They&apos;re used by us and other website owners to make our websites work more efficiently and provide vital analytical insights. Cookies set by us are called &ldquo;first-party cookies.&rdquo; Cookies set by other parties are &ldquo;third-party cookies&rdquo;, which bring third-party features or functionality to our website (e.g., advertising, interactive content, and analytics). These third-party cookies can recognize your device during visits to different websites.
      </p>
      <h2 className="text-2xl font-bold mb-4">Why do we use cookies?</h2>
      <p className="mb-8">
        We use first- and third-party cookies for several reasons. Some are the &apos;essential algorithms&apos; for our Website to operate and are referred to as &ldquo;essential&rdquo; or &ldquo;strictly necessary&rdquo; cookies. Other cookies enable us to track and target your interests, enhancing your experience, similar to how AI tailors solutions for you. Third parties serve cookies through our Website for advertising, analytics, and other purposes.
      </p>
      <h2 className="text-2xl font-bold mb-4">How can I control cookies?</h2>
      <p className="mb-8">
        You have the right to decide whether to accept or reject cookies. Your cookie rights can be exercised by setting your preferences in the Cookie Consent Manager. Essential cookies cannot be rejected as they are the &apos;core logic&apos; for providing you with our services. 
      </p>
      <p className="mb-8">
        You can find the Cookie Consent Manager in the notification banner and on our website. If you choose to reject cookies, you can still use our website, but some functionality and areas may be restricted. You can also adjust your web browser controls to accept or refuse cookies. Below, you&apos;ll find the specific types of first- and third-party cookies we use and the purposes they perform      
      </p>
      <h2 className="text-2xl font-bold mb-4">Performance and functionality cookies:</h2>
      <p className="mb-8">
        These cookies enhance the performance and functionality of our Website, just like an optimized AI model.
      </p>
      <h2 className="text-2xl font-bold mb-4">Analytics and customization cookies:</h2>
      <p className="mb-8">
        These cookies are our site&apos;s &apos;analytical AI&apos;, collecting aggregate information to help us understand how our Website is being used and customize our Website for you.
      </p>
      <h2 className="text-2xl font-bold mb-4">Unclassified cookies:</h2>
      <p className="mb-8">
        These are cookies that haven&apos;t been categorized yet, similar to unlabelled data. We&apos;re in the process of classifying these cookies with the help of their providers.
      </p>
      <h2 className="text-2xl font-bold mb-4">How can I control cookies on my browser?</h2>
      <p className="mb-8">
        Web browser controls vary for refusing cookies, so you should visit your browser&apos;s help menu for more information.      </p>
      <h2 className="text-2xl font-bold mb-4">What about other tracking technologies, like web beacons?</h2>
      <p className="mb-8">
      Like cookies, we may use similar technologies, such as web beacons (sometimes called &rdquo;tracking pixels&rdquo; or &rdquo;clear gifs&rdquo;). These are tiny graphics files that contain a unique identifier, enabling us to recognize when someone has visited our Website or opened an email, like how an AI recognizes patterns.
      </p>
      <h2 className="text-2xl font-bold mb-4">Do you use Flash cookies or Local Shared Objects?</h2>
      <p className="mb-8">
        Like websites using AI for complex tasks, websites may also use &ldquo;Flash Cookies&rdquo; (also known as Local Shared Objects or &ldquo;LSOs&rdquo;) to collect and store information about your use of our services and for other site operations.
      </p>
      <h2 className="text-2xl font-bold mb-4">Do you serve targeted advertising?</h2>
      <p className="mb-8">
        Third parties may serve cookies on your computer or mobile device to serve advertising through our Website. These companies may use information about your visits to this and other websites to provide relevant advertisements about goods and services that you may be interested in, a bit like a smart AI recommendation system.
      </p>
      <h2 className="text-2xl font-bold mb-4">How often will you update this Cookie Policy?</h2>
      <p className="mb-8">
        We may update this Cookie Policy from time to time to reflect changes to the cookies we use or for other operational, legal, or regulatory reasons, similar to how we update our AI models for better results.
      </p>
      <h2 className="text-2xl font-bold mb-4">Where can I get further information?</h2>
      <p className="mb-2">
        If you have any questions about our use of cookies or other technologies, please email us at hello@chronly.ai or by post to: Chronly Inc., State: Delaware, Street: 1007 N Orange St. 4th Floor Ste 1382, ZIP: 19801, City: Wilmington.
      </p>
    </div>
  );
};

export default CookiePolicy;
