import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  className?: string;
};

const Card = (props: Props) => {
  const { children, className } = props;
  return (
    <div className={`rounded overflow-hidden ${className}`}>
      {children}
    </div>
  );
};

export default Card;
