import { SectionWrapper } from '@/layouts';
import { Content, Header } from '../Section';
import { NoiseImageEffect } from '../NoiseEffect';
import Image from 'next/image';

const Index = () => {
  return (
    <div className="relative pt-2 md:pt-11">
      <div className="opacity-blockShadow absolute bottom-40 left-0 bg-primary blur-250 w-1/4 h-96 -z-10 dark:hidden" />
      <div className="opacity-blockShadow absolute bottom-40 right-0 bg-primary blur-250 w-1/4 h-96 -z-10 dark:hidden" />
      <div className="px-4 max-w-container m-auto">
        <SectionWrapper>
          <div className="relative space-y-3 text-center z-10 rounded-3xl bg-blurcolor md:m-0">
            <div className="px-5 pt-7 md:pt-10 pb-28 md:pb-14 md:w-full rounded-t-3xl">
              <Header
                title="Tired of Overruns Eating Your Budget?"
                caption=""
                className="space-y-3 relative mb-1 md:mb-3 md:px-14"
              />
              <div
                className="dark:hidden absolute left-[642px] top-[5335px] md:w-4/12 md:h-full translate-y-1/2 
                rounded-full bg-primary noise blur-250 w-[592px] h-[346px] -z-10 md:block"
                style={{
                  borderRadius: '50%',
                  filter: 'blur(261px)'
                }}
              />
              <Content className="flex flex-col space-y-3 justify-center relative z-10 px-8 pt-0 md:pt-6">
                <p
                  className="text-blocksText dark:text-main md:max-w-content md:text-xl md:font-normal 
                md:mx-auto mb-5 opacity-textOpacity"
                >
                  Try Chronly. See how we keep projects on track and within
                  budget, no extra hires needed.
                </p>
                <a
                  href="https://app.chronly.ai/sign-up"
                  target="_blank"
                  className="m-auto bg-primary btn-noise  hover:bg-btnHover focus:bg-btnHover
                  focus:border-btnBorderFocus transition-all text-white hover:text-white font-semibold
                  py-3 md:py-3.5 px-8 rounded-full md:text-lg cursor-pointer"
                >
                  Sign Up Now
                </a>
              </Content>
            </div>
            <Image
              src="/assets/images/readytouse.webp"
              alt="ready to use"
              width={1000}
              height={1000}
              className="absolute top-0 left-0 bottom-0 h-full m-0 rounded-3xl hidden dark:block dark:md:hidden"
            />
            <Image
              src="/assets/images/bgimglight.webp"
              alt="bgimgLight"
              width={1000}
              height={1000}
              className="absolute top-0 left-0 bottom-0 h-full md:hidden block dark:hidden m-0"
            />

            <div style={{ position: 'absolute', top: '0px', left: '0px' }}>
              <NoiseImageEffect
                backgroundImage="assets/images/bgdesk.webp"
                className="absolute inset-0 rounded-[32px] transform scale-2 object-cover 
                object-center hidden dark:md:block"
                borderRadius="rounded-screenRadius2"
              >
                <Image
                  src="/assets/images/bgdesk.webp"
                  alt="ready to use"
                  width={1000}
                  height={1000}
                  className="rounded-screenRadius2 h-full w-full z-10"
                />
              </NoiseImageEffect>
            </div>

            <Image
              src="/assets/images/bglightdesk.webp"
              alt="ready to use"
              width={1000}
              height={1000}
              className="absolute top-0 left-0 bottom-0 w-full h-full md:block md:rounded-3xl hidden dark:hidden"
            />
          </div>
        </SectionWrapper>
        <div
          className="dark:hidden absolute left-[449px] top-[5858px] translate -translate-y-[5555px]
          bg-bgLight noise blur-125 w-[242px] h-[242px] -z-10 md:block"
        />
        <div
          className="absolute bottom-0 top-[5858px] translate -translate-y-[5555px] right-[368px] dark:hidden
          bg-bgLight noise blur-125 w-[242px] h-[242px] -z-10 md:block"
        />
        <div
          className="opacity-25 dark:hidden absolute top-0 left-[-48px]
          bg-blueColor03 noise blur-250 w-[594px] h-[594px] -z-10 md:block"
        />
        <div
          className="opacity-25 absolute bottom-0 top-1/2 translate -translate-y-1/2 left-[1347px] dark:hidden
          bg-blueColor06 noise blur-250 w-[594px] h-[594px] -z-10 md:block"
        />
      </div>
    </div>
  );
};

export default Index;
