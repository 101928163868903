import { ReactNode } from 'react';

type ICardContent = {
  children?: string | ReactNode;
  className?: string;
};
const Content = (props: ICardContent) => {
  const { children, className } = props;
  return <div className={`flex ${className}`}>{children}</div>;
};

export default Content;
