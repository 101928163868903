import { SectionWrapper } from '@/layouts';
import { Content, Header } from '../Section';
import AboutCard from './AboutCard';

const Index = () => {
  return (
    <SectionWrapper className="overflow-visible relative">
      <div
        className="opacity-40 absolute bg-primary blur-250 w-full h-full top-36 left-0 -z-10 right-40 
      rounded-full md:hidden"
      ></div>
      <div className="relative px-4 pt-8 md:pt-24 md:pb-8 -mt-1">
        <Header
          title="How Chronly Helps"
          caption="TESTIMONIALS"
          className="px-6 md:px-10 md:mb-4"
        />
        <Content
          className="max-w-container m-auto flex flex-col pt-1 md:pt-6 md:flex-row space-y-3 
        md:space-y-0 justify-center  md:space-x-6"
        >
          <AboutCard
            testimonial={
              <>
                <p className="mb-3 md:mb-5 py-2 md:h-[200px] md:text-lg md:font-normal rtl:text-right">
                  {
                    "Since we use Chronly, we're finally catching those little hiccups way before they turn into big headaches. It's changed how we wrap up sprints—no more surprises. And on the budget side, we're doing a lot better because we don't need to hire extra developers to stay on track."
                  }
                </p>
              </>
            }
            description="Team Leader in Pinely International"
            name="Konstantin Vasilev"
            img="/assets/images/KonstantinPic.png"
            imgClassName="md:mr-6"
            className="text-center w-full rounded-3xl bg-bgLight text-blocksText dark:bg-blurColor dark:text-main"
          />
          <div
            className="opacity-30 dark:opacity-70 absolute top-1/2 left-1/3 rounded-full dark:bg-blueColor05
          bg-blueColor03 noise blur-250 md:w-[438px] md:h-[438px] -z-10 md:block "
          />
          <div
            className="opacity-25 dark:opacity-65 absolute rounded-full dark:bg-primary top-[450px] left-[55px]
          bg-blueColor03 noise blur-125 w-[268px] h-[267px] -z-10 md:hidden "
          />
          <div
            className="opacity-25 dark:opacity-65 absolute rounded-full dark:bg-primary top-[900px] left-[55px]
          bg-blueColor03 noise blur-125 w-[268px] h-[267px] -z-10 md:hidden "
          />
          <AboutCard
            testimonial={
              <p className="mb-5 py-2 md:text-lg md:font-normal md:h-[200px] rtl:text-right">
                {
                  "Got Chronly to see who's actually working and on track with deadlines. It shows me real-time work status, so if results look off, I can step in right away.  It links with Jira, logs hours itself. Saves time, cuts out the need for micromanaging."
                }
              </p>
            }
            description="CEO Limitless Vision game studio"
            name="Sergei Prozherin"
            img="/assets/images/Sergei.png"
            imgClassName="md:mr-6"
            className="text-center w-full rounded-3xl bg-bgLight text-blocksText dark:bg-blurColor dark:text-main"
          />
          <div
            className="dark:opacity-75 absolute dark:bottom-28 dark:top-1/2 
            dark:translate dark:translate-y-1/2 dark:left-663 dark:rounded-full dark:bg-blueColor04
          noise dark:blur-250 md:dark:w-[594px] md:dark:h-[594px] dark:-z-10 hidden dark:md:block"
          />
        </Content>
      </div>
    </SectionWrapper>
  );
};

export default Index;
