import React, { ReactElement, ReactNode } from 'react';
import Button from '../Button';
import Link from 'next/link';

interface ItextHolder {
  title: string;
  heading: ReactElement | ReactNode;
  content: React.ReactNode;
}
const TextHolder: React.FC<ItextHolder> = ({ title, heading, content }) => {
  return (
    <>
      <div className="py-5 md:py-visualPadding overflow-hidden">
        <div className="absolute -top-8 opacity-headerShadow bg-primary noise blur-250 w-96 h-96 -z-10 -right-10 hidden" />
        <div className="lg:max-w-container  mx-auto px-4">
          <div className="text-center">
            <h2
              className="text-mobileTitle mb-2 md:mb-2 font-bold tracking-titleMobileSpacing lg:tracking-titleSpacing
              md:text-xl md:font-bold noise-text "
              data-text={title}
            >
              {title}
            </h2>
            {heading}
            <div className="flex justify-center">
              <p
                className="lg:text-heading5 md:text-base font-normal leading-visualText md:leading-visualText
            md:max-h-114px md:max-w-containerMedium  mb-10 md:mb-14 text-blocksText dark:text-bgLight opacity-textOpacity"
              >
                {content}
              </p>
            </div>
          </div>
          <Link href="https://app.chronly.ai/sign-up">
            <Button btntype="Start Now" className="text-center -mt-2" />
          </Link>
        </div>
        <div
          className="opacity-20 absolute bottom-28 bg-primary blur-250 h-3/4 top-topShadow w-2/4
        left-1/4 -z-10 hidden dark:hidden"
        />
        <div className="opacity-20 absolute top-0 bg-primary blur-250 h-1/4 w-1/4 left-leftShadow -z-10 dark:hidden" />
      </div>
    </>
  );
};
export default TextHolder;
