import React, { ReactNode } from 'react';
import LazyLoad from 'react-lazyload';
import Image from 'next/image';


interface NoiseEffectProps {
  children: ReactNode;
  className?: string; 
}

const NoiseEffect: React.FC<NoiseEffectProps> = ({ children, className }) => {
  return (
    <div className={`relative ${className}`}> 
      {children}
      <LazyLoad height={200} once>
        <Image
          src="/assets/images/noise.webp"
          alt="noise"
          width={2000}
          height={2000}
          className="absolute inset-0 object-cover opacity-10 w-full h-full "
          style={{ zIndex: -1 }}
        />
      </LazyLoad>
    </div>
  );
};


interface NoiseImageEffectProps {
  children?: ReactNode;
  className?: string; 
  backgroundImage: string;
  borderRadius?: string;
}

export const NoiseImageEffect: React.FC<NoiseImageEffectProps> = ({ children, className, backgroundImage, borderRadius }) => {
  const borderRadiusClass = borderRadius ? borderRadius : '';

  return (
    <div className={`relative ${className} overflow-hidden ${borderRadiusClass}`} 
    style={{ backgroundImage: `url(${backgroundImage})`}}> 
      {children}
      <div
        className={`absolute inset-0 object-cover opacity-10 w-full h-full ${borderRadiusClass}`}
        style={{ 
          backgroundImage: "url(/assets/images/noise.webp)",
          backgroundBlendMode: "luminosity",
          zIndex: 1
        }}
      />
    </div>
  );
};

export default NoiseEffect;
