const PilotIcon = (props: React.SVGAttributes<SVGElement>) => {
    const { width = '31', height = '29', ...rest } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 300 300"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                fill="#00b67a"
                d="M271.3 98.6H167.7L135.7 0l-32.1 98.6L0 98.5l83.9 61L51.8 258l83.9-60.9 83.8 60.9-32-98.5 83.8-60.9z"
            />
            <path
                fill="#005128"
                d="M194.7 181.8l-7.2-22.3-51.8 37.6z"
            />
        </svg>
    );
};




export default PilotIcon;
