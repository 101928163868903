import { faSun, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import React from 'react';
import Image from 'next/image';

const navMenu = [
  { menu: 'About us' },
  { menu: 'Pricing' },
  { menu: 'Rewards' },
  { menu: 'Partners' },
  { menu: 'How it works' },
  { menu: 'Careers' },
  { menu: 'Blog' }
];
interface IMENU {
  toggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
}
const MobileMenu = ({ toggleMenu }: IMENU) => {
  return (
    <div className="pt-8 md:hidden m-auto bg-bgLight text-white dark:bg-black 
    fixed left-0 top-0 duration-500 w-full h-full overflow-y-auto overflow-x-hidden z-50">
      <div className="opacity-80 fixed top-0 right-0 bg-primary w-full bottom-0 z-10 dark:hidden"></div>
      <div className="px-8 overflow-hidden">
        <div className="mx-auto flex justify-between items-center mb-72 z-20 relative">
          <strong className="logo">
            <Link href="#">
              <Image
                width={100}
                height={100}
                src="/assets/images/logoDark.svg"
                alt="logoDark"
                className="hidden h-auto w-auto dark:block"
              />
              <Image
                width={100}
                height={100}
                src="/assets/images/lightMenuLogo.svg"
                alt="lightMenuLogo"
                className="dark:hidden"
              />
            </Link>
          </strong>
          <FontAwesomeIcon
            onClick={() => toggleMenu((prev) => !prev)}
            icon={faTimes}
            className="w-4 h-auto"
          />
        </div>

        <div className="border-dashed border-b-2 border-bgLight z-20 relative  border-opacity-30">
          <ul className="block mb-7">
            {navMenu.map((data) => (
              <li key={data.menu} className="mb-4">
                <Link href="/" className="text-2xl	font-semibold">
                  {data.menu}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="block pt-8 pb-4 border-dashed border-b-2 border-bgLight z-20 relative border-opacity-30">
          <p className="text-2xl font-semibold mb-3">FAQs</p>
          <p className="text-2xl font-semibold mb-3">Support</p>
          <p className="text-2xl font-semibold mb-3">Forums</p>
        </div>
        <div className="block text-center pt-8 z-20 relative pb-6">
          <div className="bg-white dark:bg-primary opacity-80 dark:opacity-50 blur-[125px] 
          absolute -bottom-20 left-1/2 w-64 h-64 -translate-x-1/2" />
          <Link
            href="/"
            className="border border-primary bg-black py-2 px-4 rounded-3xl dark:shadow-btnShadow 
            block mb-5 text-base font-normal relative"
          >
            Register
          </Link>
          <Link href="/" className="mb-5 text-base font-normal relative">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};
export default MobileMenu;
